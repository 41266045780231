import { ref, watch, computed } from '@vue/composition-api'
import { getContactFacility, getContactCategoryFacility } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@/@core/utils/useFilters'

export default function useContactsList() {
  const { t } = useUtils()
  const { updateFilterSubmodule, getFilterBySubmodule } = useFilters()
  const { hasPermission } = usePermissions()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  const listTable = ref([])
  const totalListTable = ref(0)

  // {
  //   "id": 1,
  //   "facility_id": 18,
  //   "first_name": null,
  //   "last_name": null,
  //   "phone_code": null,
  //   "phone_prefix": null,
  //   "phone_number": null,
  //   "email": null,
  //   "status": "A",
  //   "categories_ids": []
  // }
  // { text: t('users.lastname').toUpperCase(), value: 'last_name', show: true },
  // { text: t('status.status').toUpperCase(), value: 'status', show: true },
  const tableColumns = computed(() => [
    { text: t('users.name').toUpperCase(), value: 'fullname', show: true },
    { text: t('users.phone').toUpperCase(), value: 'phone_number', show: true },
    { text: t('users.email').toUpperCase(), value: 'email', show: true },
    { text: t('Categories').toUpperCase(), value: 'categories_str', show: true },

    // {
    //   text: t('tooltip.actions').toUpperCase(),
    //   value: 'actions',
    //   align: 'center',
    //   sortable: false,
    //   show: hasActionPermission(115, 117, 118),
    // },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const listTableCategories = ref([])
  const totalListTableCategories = ref(0)

  // { text: t('sports.description').toUpperCase(), value: 'description', show: true },
  const tableColumnsCategories = computed(() => [
    { text: t('sports.name').toUpperCase(), value: 'name', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },

    // {
    //   text: t('tooltip.actions').toUpperCase(),
    //   value: 'actions',
    //   align: 'center',
    //   sortable: false,
    //   show: hasActionPermission(124, 126, 127),
    // },
  ])
  const computedTableColumnsCategories = computed(() => tableColumnsCategories.value.filter(e => e.show))

  const searchQuery = ref('')
  const searchQueryCat = ref('')
  const filters = ref(null)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const optionsCategories = ref({
    sortBy: ['first_name'],
    sortDesc: [true],
  })

  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const timer = ref(null)

  const filterContact = item => {
    let valid = false
    if (item.fullname && item.fullname.toLowerCase().includes(searchQuery.value.toLowerCase())) valid = true
    if (item.phone_number && item.phone_number.toLowerCase().includes(searchQuery.value.toLowerCase())) valid = true
    if (item.email && item.email.toLowerCase().includes(searchQuery.value.toLowerCase())) valid = true

    return valid
  }

  const fetchContacts = async () => {
    const response = await getContactFacility(configFacility.value || 0)
    if (response.ok) {
      const filterOption = {}
      filters.value = null
      if (searchQuery.value) filterOption.search = searchQuery.value
      filters.value = filterOption

      // const filteredData = response.data
      const filteredData = response.data.filter(item => filterContact(item))

      // const filteredData = response.data.filter(
      //   item =>
      //     /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      //     item.first_name.toLowerCase().includes(searchQuery.value) || item.last_name.toLowerCase().includes(searchQuery.value),
      // )

      listTable.value = filteredData
      totalListTable.value = filteredData.length
    } else {
      listTable.value = []
      totalListTable.value = 0
    }
    loading.value = false
  }

  const fetchContactCategories = async () => {
    const response = await getContactCategoryFacility(configFacility.value || 0)
    if (response.ok) {
      const filterOption = {}
      filters.value = null
      if (searchQueryCat.value) filterOption.searchCat = searchQueryCat.value
      filters.value = filterOption

      const filteredData = response.data.filter(
        item =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (item.name && item.name.toLowerCase().includes(searchQueryCat.value)) ||
        (item.description && item.description.toLowerCase().includes(searchQueryCat.value)),
      )

      listTableCategories.value = filteredData
      totalListTableCategories.value = filteredData.length
    } else {
      listTableCategories.value = []
      totalListTableCategories.value = 0
    }
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.searchCat) searchQueryCat.value = value.searchCat
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      updateFilterSubmodule('views-contact-list', 'contacts', 'search', searchQuery.value)
      await fetchContacts()
    }, 1000)
  }

  const getDataBySearchCat = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      updateFilterSubmodule('views-contact-list', 'categories', 'search', searchQueryCat.value)
      await fetchContactCategories()
    }, 1000)
  }

  watch([configFacility, configOrganization], async () => {
    searchQuery.value = ''
    searchQueryCat.value = ''

    timer.value = setTimeout(async () => {
      await fetchContacts()
      await fetchContactCategories()
    }, 400)
  })

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch()
  })

  watch([searchQueryCat], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearchCat()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchContacts()
    }
  })

  watch([configFacility], async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchContacts()
      await fetchContactCategories()
    }, 300)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    listTable,
    tableColumns,
    computedTableColumns,
    totalListTable,

    listTableCategories,
    tableColumnsCategories,
    computedTableColumnsCategories,
    totalListTableCategories,

    searchQuery,
    searchQueryCat,
    loading,
    options,
    optionsCategories,
    filters,

    configOrganization,
    configFacility,

    fetchContacts,
    fetchContactCategories,
    filterContact,
    resolveStatusVariant,
    resolveStatusText,
    hasPermission,
    setFilters,
    setOptions,
    updateFilterSubmodule,
    getFilterBySubmodule,
  }
}
